<template>
  <div>
    <!-- Filter Bar -->
    <FilterBar />

    <!-- Table -->
    <vuetable data-path="influencers"
              ref="vuetable"
              @vuetable:pagination-data="onPaginationData"
              pagination-path="pagination"
              :api-url="apiUrl"
              :fields="fields"
              :http-options="httpOptions"
              :css="css.table"
              :per-page="10"
              :append-params="moreParams"
              :query-params="{
                sort: 'sort',
                page: 'page',
                perPage: 'perPage'
              }"
              track-by="userId">
    </vuetable>

    <!-- Pagination -->
    <div class="d-flex align-items-center justify-content-end">
      <vuetable-pagination-info ref="paginationInfo"></vuetable-pagination-info>
      <vuetable-pagination ref="pagination"
                           @vuetable-pagination:change-page="onChangePage"
                           :css="css.pagination">
      </vuetable-pagination>
    </div>
  </div>
</template>

<script>
  import Vue from 'vue';
  import TableStyle from '../table-bootstrap-css.js';

  const Vuetable = () => import('vuetable-2/src/components/Vuetable');
  const VuetablePagination = () =>
  import('vuetable-2/src/components/VuetablePagination');
  const VuetablePaginationInfo = () =>
  import('vuetable-2/src/components/VuetablePaginationInfo');
  const FilterBar = () => import('./FilterBar.vue');

  export default {
    name : 'InfluencerList',
    data() {
      return {
        apiUrl : process.env.VUE_APP_ROOT_API +
          'api/broadcast/influencers',
        moreParams  : {},
        css         : TableStyle,
        httpOptions : {
          headers : {
            'Authorization' : `Bearer ${this.$store.state.auth.accessToken}`,
          },
        },
        fields : [
          {
            name       : 'userId',
            title      : 'ID',
            sortField  : 'user_id',
            direction  : 'asc',
            titleClass : 'text-center',
            dataClass  : 'text-center',
          },
          {
            name      : 'email',
            title     : 'Email',
            sortField : 'email',
            direction : 'asc',
          },
          {
            name      : 'nickname',
            title     : 'Nickname',
            sortField : 'nickname',
            direction : 'asc',
          },
          {
            name      : 'firstname',
            title     : 'First Name',
            sortField : 'firstname',
            direction : 'asc',
          },
          {
            name      : 'lastname',
            title     : 'Last Name',
            sortField : 'lastname',
            direction : 'asc',
          },
          {
            name      : 'timezone',
            title     : 'Timezone',
            sortField : 'timezone',
            direction : 'asc',
          },
        ],
      }
    },
    components : {
      FilterBar,
      Vuetable,
      VuetablePagination,
      VuetablePaginationInfo,
    },
    methods : {

      /**
       * Transform data
       *
       * @param data
       * @return {object}
       */
      transform(data) {
        const transformed = {};

        transformed.pagination = {
          total         : data.total,
          per_page      : data.perPage,
          current_page  : data.currentPage,
          last_page     : data.lastPage,
          next_page_url : data.nextPageUrl,
          prev_page_url : data.prevPageUrl,
          from          : data.from,
          to            : data.to,
        };

        transformed.influencers = [];

        for (let i = 0; i < data.data.length; i++) {
          transformed.influencers.push({
            userId    : data.data[i].user_id,
            email     : data.data[i].email,
            nickname  : data.data[i].nickname,
            firstname : data.data[i].firstname,
            lastname  : data.data[i].lastname,
            timezone  : data.data[i].timezone,
          });
        }
        
        return transformed;
      },

      /**
       * Set Pagination Info
       * @param paginationData
       */
      onPaginationData(paginationData) {
        this.$refs.pagination.setPaginationData(paginationData);
        this.$refs.paginationInfo.setPaginationData(paginationData);
      },

      /**
       * Change page
       * @param page
       */
      onChangePage(page) {
        this.$refs.vuetable.changePage(page);
      },

      /**
       * On Filter Set
       * @param filterText
       */
      onFilterSet(filterText) {
        this.moreParams = {
          'filter' : filterText,
        };
        Vue.nextTick(() => this.$refs.vuetable.refresh());
      },

      /**
         * On Reset Filter
         */
      onFilterReset() {
        this.moreParams = {};
        Vue.nextTick(() => this.$refs.vuetable.refresh());
      },

      /**
       * Update Influencer's List
       */
      onAddInfluencer() {
        this.$refs.vuetable.reload();
      },
    },
    mounted() {
      this.$events.$on('filter-set', eventData => this.onFilterSet(eventData));
      this.$events.$on('filter-reset', () => this.onFilterReset());
      this.$events.$on('add-influencer', () => this.onAddInfluencer());
    },
  }
</script>

<style lang="scss" scoped>
  @import "../../../assets/scss/components/admin/influencers/influencer-list";
</style>